import React, { useEffect, useRef, useState } from "react";
import LandingPage from "../components/home/landingPage/LandingPage";
import QuickLink from "../components/home/quicklinks/QuickLink";
import Specialties from "../components/home/specialities/Specialties";
import AboutUs from "../components/home/about/AboutUs";
import Department from "../components/home/department/Department";
import Experienced from "../components/home/experience/Experienced";
import ChooseDoctor from "../components/home/selectDoctor/ChooseDoctor";
import Testimonials from "../components/home/testimonials/Testimonials";
import ContactUs from "../components/home/contactUs/ContactUs";
import WhyChooseUs from "../components/home/whyChooseUs/WhyChooseUs";
import NewsBlogs from "../components/home/blogs/NewsBlogs";
import Chatbot from "../components/home/chatbot/Chatbot";
// import ChatAssistant from "../components/home/ChatAssistant/ChatAssistant";
import GroupHospitals from "../components/home/ourGroupHospitals/GroupHospitals";
import { useLocation } from "react-router-dom";
import FlipBook from "../components/home/flipBook/FlipBook";

const Home = () => {
  const [showBot, setShowBot] = useState(false);
  const location = useLocation()

  useEffect(() => {
    const scrollToHashElement = () => {
      if (location.hash) {
        const elementId = location.hash.substring(1);
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    const timeoutId = setTimeout(scrollToHashElement, 100);

    return () => clearTimeout(timeoutId);
  }, [location]);

  return (
    <div>
      <LandingPage />
      <QuickLink />
      <AboutUs />
      <Specialties />
      <Department />
      <Experienced />
      <GroupHospitals />
      <ChooseDoctor />
      <NewsBlogs />
      <FlipBook />
      <WhyChooseUs />
      <ContactUs />
      <Testimonials />
      <QuickLink />

      {/* <ChatAssistant showBot={showBot} setShowBot={setShowBot}  /> */}
      {showBot && <Chatbot setShowBot={setShowBot} />}
    </div>
  );
};

export default Home;
