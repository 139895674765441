import React from "react";
import { useTranslation } from "react-i18next";
import { FaPhoneVolume } from "react-icons/fa6";

const CallBtn = () => {
  const { t } = useTranslation();

  return (
    <div className=" cursor-pointer  hover:scale-105 transition duration-300">
      <a
        href="https://wa.me/4713100100?text=Hi"
        target="blank"
        className="bg-gray-300 flex items-center justify-center rounded-full py-3 px-5 relative group" // Added 'group' class
      >
        {/* <span className="px-3 text-sm pt-1">Chat With Us </span> */}

        <div className="p-2 hidden group-hover:flex bg-primary_color text-white items-center absolute text-nowrap right-12 self-center rounded-lg hover:transition-all duration-300 ease-in-out">
          <span className="text-xs px-3">Chat with Dr. Forte</span>
        </div>

        <div
          className="p-1 bg-primary_color text-white flex items-center absolute 
            align-start  lg:-left-5 rounded-full border-4 border-white"
        >
          {/* <FaPhoneVolume /> */}
          <img src={require("../../assets/icon.png")} alt="" className="w-9" />
        </div>
      </a>
    </div>
  );
};

export default CallBtn;
