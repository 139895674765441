import cover from '../assets/blogs/1.jpeg'
import banner1 from '../assets/blogs/2.jpeg'
import blogBanner2 from '../assets/blogs/3.jpg'
import blogBanner3 from '../assets/blogs/4.jpg'
import blogBanner4 from '../assets/blogs/5v1.jpg'
import blogBanner4v1 from '../assets/blogs/5v2.jpg'
import blogBannerE1 from '../assets/blogs/E1.jpg'
import blogBannerE2 from '../assets/blogs/E2.jpg'
import blogBannerE2v2 from '../assets/blogs/E2v2.jpg'

export const Blog = [
    {
        id: "@SP_medifort_blog_5",
        banner: blogBanner4v1,
        icon: blogBanner4,
        title: "On World Stroke Day, know the 'FAST' tip on strokes",
        postDate: "29/10/2024",
        category: "Blog",
        previewTitle: ``,
        author: "Dr Manorama Devi K Rajan",
        content: [
            {
                id: 1, subheading: "",
                para: `Stroke is a time dependent medical emergency all over the world. It is currently the
single largest cause of disability and the 3rd largest cause of death globally. Individuals’ lifetime risk of stroke is currently one in four
`
            },
            {
                id: 2, subheading: "",
                para: `To put it in perspective, stroke is a neurological deficit attributed to an acute focal injury of the central nervous system by a vascular cause Globally 1 in 4 adults over the age of 25 will have a stroke in their lifetime. Latest estimates show that about 15 million
strokes occur worldwide each year leading to five million deaths. Also, five million people are left disabled by stroke every year
`
            },
            {
                id: 3, subheading: "", para: `In India, incidence of strokes occurrence is 148 per 1,00,000 people a year`,
            },
            {
                id: 4, subImages: "", subheading: ``,
                para: `In Kerala, one study from Thiruvananthapuram found that the incidence of stroke is
120-140 per 1,00,000 people per year. Mortality rate was 25% and moderate to severe disability was 40-50 per cent. Another study conducted at Kochi in 2022 showed that 12 percent of people affected were under the age of 45. The study also mentioned a
shocking finding that 85.7% of people were unaware of stroke symptoms

`,
            },
            {
                id: 5, subheading: "", para: `Therefore, understanding strokes and its symptoms is important since in majority of the cases the patient can be saved with timely medical attention`,
            },
            {
                id: 6, subheading: "", para: `Broadly there are two main types of strokes`,
            },
            {
                id: 7, subheading: "", para: `The first is an ischemic stroke. It occurs when the blood supply to part of the brain is blocked or reduced. This prevents brain tissue from getting oxygen and nutrients and
the brain cells begin to die in minutes. About 85% of the strokes are ischemic stroke
`,
            },
            {
                id: 8, subheading: "", para: `The second type of stroke is hemorrhagic. It occurs when a blood vessel in the brain leaks or bursts and causes bleeding in the brain. 
                Transientischemic attacks (TIA) are often called 'mini strokes'. It resembles an ischemic stroke but lasts a short time of five minutes to few hours Because of quick 
                recovery, TIA may not cause permanent damage, but it is often a warning sign of a full ischemic stroke in future.`,
            },
            {
                id: 9, subheading: "Symptoms of a stroke are the following", para: ``,
                subData: [
                    { id: 1, heading: "", b: "F. Face --", content: `Unilateral facial weakness or drooping of face. Ask the patient to smile or show the teeth tofind out the weakness` },
                    {
                        id: 2, heading: "", b: "A. Arm --", content: `Arm or leg weakness, mostly one side, inability to raise the arm fully or inability to hold or squeeze something.`
                    },
                    {
                        id: 3, heading: "", b: "S. Speech --", content: `Inability or difficulty to understand speech or produce speech slurred speech, or difficulty to repeat simple sentence as 'sky is blue' and 'now raining'`
                    },
                    {
                        id: 4, heading: "", b: "T. Time --", content: `If any of the above symptoms call the emergency services or reach immediately to the nearby hospital where stroke treatment is available`
                    },
                ]
            },
            {
                id: 10, subheading: "Time is brain, time lost is brain lost", para: `Remember to note the time of the first sign and inform this time to the emergency physician`,
            },
            {
                id: 11, subheading: "", para: `After arriving at the hospital, the emergency team will evaluate immediately and call the stroke team. Necessary blood work-up, imaging studies like CT SCAN/MRI with or
without angiography will be carried out depending on the type of stroke. If arriving within four to five hours clot lysing like thrombolysis will be initiated. The stroke team will
`,
            },
            {
                id: 12, subheading: "", para: `decide further management such as, thrombectomy, medical management, surgical intervention if there is hemorrhagic stroke. Simultaneously, taking care to control the risk factors if any. Post stroke rehabilitation also will start early.`,
            },
            {
                id: 13, subheading: "", para: `The key takeaway is that act fast at the first sign of stroke. Use the letters 'FAST' to spot the signs of stroke and seek medical attention immediately.`,
            },
        ]
    },
    {
        id: "@SP_medifort_Event_1",
        banner: blogBannerE1,
        icon: blogBannerE1,
        title: "Breast Cancer Awareness Book Release Ceremony",
        postDate: "18/10/2024",
        category: "Event",
        previewTitle: ``,
        author: "SP Medifort",
        content: [
            {
                id: 1, subheading: "",
                para: `Join us for a special event to commemorate Breast Cancer Awareness Month.`
            },
            {
                id: 2, subheading: "",
                para: `We are excited to announce the release of a new book authored by our esteemed oncologists at SP Medifort: Dr. Boben Thomas, Dr. Chandramohan K, Dr. Ajai Sasidhar, and Dr. Teena Nelson. This comprehensive guide will provide valuable information and insights into breast cancer prevention, diagnosis, and treatment.`
            },
            {
                id: 3, subheading: "Chief Guest:", para: `We are honored to have the renowned actress Ms.Mallika Sukumaran, as our chief guest for this event. Her presence will add a significant dimension to our celebration of breast cancer awareness.`,
            },
            {
                id: 4, subImages: "", subheading: `Community Impact`,
                para: `We look forward to your participation!`,
            },
            {
                id: 5, subheading: "", para: ``,
                subData: [
                    { id: 1, heading: "", b: "Date: ", content: "Saturday, October 19, 2024" },
                    {
                        id: 2, heading: "", b: "Time:", content: `12 Noon`
                    },
                    {
                        id: 3, heading: "", b: "Venue:", content: `SP Medifort, Eanchakkal`
                    },
                ]
            },
        ]
    },
    {
        id: "@SP_medifort_Event_2",
        banner: blogBannerE2,
        icon: blogBannerE2v2,
        title: "Free Breast Cancer Screening Camp",
        postDate: "18/10/2024",
        category: "Event",
        previewTitle: ``,
        author: "SP Medifort",
        content: [

            {
                id: 2, subheading: "", para: `Join us for a FREE Breast Cancer Screening Camp conducted by our expert oncologists!`,
                subData: [
                    { id: 1, heading: "", b: "Clinical breast examination", content: "" },
                    {
                        id: 2, heading: "", b: " Mammography (limited slots)", content: ``
                    },
                    {
                        id: 3, heading: "", b: "Ultrasound (limited slots)", content: ``
                    },
                    {
                        id: 4, heading: "", b: `Oncologist consultation`, content: ``
                    },
                    {
                        id: 4, heading: "", b: `20% discount on investigations and advanced cancer health check`, content: ``
                    },
                ]
            },
            {
                id: 3, subheading: "Recommended for :", para: `Women with:`,
                subData: [
                    { id: 1, heading: "", b: "Family history of breast cancer", content: "" },
                    {
                        id: 2, heading: "", b: "Lump, discharge, bleeding, or redness", content: ``
                    },
                    {
                        id: 3, heading: "", b: "Pitted skin or change in breast appearance", content: ``
                    },
                    {
                        id: 4, heading: "", b: `Age 40+ years`, content: ``
                    },
                ]
            },
            {
                id: 4, subheading: "Meet Our Expert Oncologists:", para: ``,
                subData: [
                    { id: 1, heading: "", b: "Dr. Chandramohan K (MBBS, MS, MCh - Surgical Oncology)", content: "" },
                    {
                        id: 2, heading: "", b: "Dr. Ajai Sasidhar (MBBS, MS, MCh - Surgical Oncology, AIIMS)", content: ``
                    },
                    {
                        id: 3, heading: "", b: "Dr. Boben Thomas (MBBS, MD, DNB, FPO, ECMO - Medical Oncology)", content: ``
                    },
                    {
                        id: 4, heading: "", b: `Dr. Teena Nelson (MBBS, DNB - Radiation Oncology)`, content: ``
                    },
                ]
            },
            {
                id: 5, subheading: "Registration:", para: `Call +91 471 3100 100 to register.`,
                subData: [
                ]
            },
            {
                id: 1, subheading: "Don't Miss This Opportunity!",
                para: `Take proactive steps towards breast health and avail this free screening opportunity.`
            },
            {
                id: 3, subheading: "", para: ``,
                subData: [
                    { id: 1, heading: "", b: "Date: ", content: "October 15 - 31, 2024" },
                    {
                        id: 2, heading: "", b: "Time:", content: `9 AM - 3 PM`
                    },
                    {
                        id: 3, heading: "", b: "Venue:", content: `SP Medifort, International Airport Service Rd, Eanchakkal, Thiruvananthapuram`
                    },
                ]
            },
        ]
    },
    {
        id: "@SP_medifort_Blog_3",
        banner: blogBanner3,
        icon: blogBanner3,
        title: "SP Medifort Hospital Hosts Free Oncology Screening Camp: Early Detection Saves Lives",
        postDate: "27/08/2024",
        category: "Health",
        previewTitle: `Nurturing a Healthier Future`,
        author: "SP Medifort",
        content: [
            {
                id: 1, subheading: "SP Medifort Hospital Hosts Free Oncology Screening Camp: Early Detection Saves Lives",
                para: `At SP Medifort Hospital, we are committed to ensuring that every individual has access to quality healthcare services, 
                especially when it comes to fighting life-threatening diseases like cancer. In line with our vision of putting "You First, Always," 
                we recently organized a free oncology screening camp aimed at creating awareness about cancer and promoting early detection.`
            },
            {
                id: 2, subheading: "Why Early Detection Matters",
                para: `Cancer, when detected early, can be treated more effectively, improving survival rates and reducing treatment costs. The focus 
                of the screening camp was on identifying early signs of cancer, providing people with a fighting chance against this formidable disease. 
                Our expert team of oncologists was on-site to offer consultations and perform screenings, ensuring that participants received the necessary guidance and support.` },
            {
                id: 3, subheading: "Services Provided at the Camp", para: `The screening camp provided a comprehensive range of services, including:`,
                subData: [
                    { id: 1, heading: "", b: "Oncology Screenings:", content: "Our specialists conducted detailed screenings to detect any early signs of cancer, with a focus on breast, cervical, and oral cancers, which are prevalent in India." },
                    {
                        id: 2, heading: "", b: "Expert Consultations:", content: `Attendees had the opportunity to consult with leading oncologists who provided personalized advice and information on further steps if any abnormalities were detected.`
                    },
                    {
                        id: 3, heading: "", b: "Health Education:", content: `Educational sessions were conducted to raise awareness about cancer prevention, symptoms, and the importance of regular check-ups. Emphasis was placed on lifestyle changes that can reduce cancer risk, such as quitting smoking, maintaining a healthy diet, and exercising regularly.`
                    },
                ]
            },
            {
                id: 4, subImages: "", subheading: `Community Impact`,
                para: `The camp saw a significant turnout, reflecting the community's growing awareness of the importance of cancer screening. Our hospital takes pride in being able to extend this critical service to people who may not otherwise have access to specialized oncology care. By providing free screenings, SP Medifort Hospital aims to empower individuals to take control of their health and make informed decisions about their wellbeing.`,
            },
            {
                id: 5, subImages: "", subheading: "Looking Ahead",
                para: `We believe that initiatives like this are crucial in the fight against cancer. SP Medifort Hospital is dedicated to continuing our outreach efforts, 
                bringing essential healthcare services to those who need them most. We encourage everyone to prioritize their health by undergoing regular check-ups and staying 
                informed about the risks and symptoms of cancer.`,
            },
            {
                id: 6, subImages: "", subheading: " ",
                para: `Together, we can make a difference. For more information on upcoming camps or to book an appointment with our oncology team, please visit our website or contact us at [insert contact information].`,
            },
            {
                id: 7, subImages: "", subheading: " ",
                para: `At SP Medifort Hospital, your health is always our priority.`,
            },
        ]
    },
    {
        id: "@SP_medifort_Blog_2",
        banner: blogBanner2,
        icon: blogBanner2,
        title: "World Breastfeeding Week 2024: Nurturing a Healthier Future",
        postDate: "27/08/2024",
        category: "Health",
        previewTitle: `Nurturing a Healthier Future`,
        author: "SP Medifort",
        content: [
            {
                id: 1, subheading: "Nurturing a Healthier Future", para: `At SP Medifort Hospital, we understand that providing comprehensive healthcare 
                means caring for every stage of life. This August, we join the global community in celebrating World Breastfeeding Week (August 1-7), 
                an important initiative that emphasizes the vital role of breastfeeding in  fostering healthier lives for infants, mothers, and families.`
            },
            {
                id: 2, subheading: "Why Breastfeeding Matters", para: `Breastfeeding is a natural and powerful way to ensure newborns receive essential 
                nutrients and antibodies that promote optimal growth and immunity. It is recommended as the primary source of nutrition for infants during 
                their first six months and continues to provide numerous health benefits as complementary foods are introduced.` },
            {
                id: 3, para: `Some of the key benefits of breastfeeding include:`,
                subData: [
                    { id: 1, heading: "", b: "Boosting Immunity:", content: " Breast milk contains antibodies that help protect infants from infections, illnesses, and diseases." },
                    {
                        id: 2, heading: "", b: "Supporting Growth and Development:", content: `The nutrients in breast milk are perfectly balanced for a baby's developmental needs, promoting healthy physical and cognitive growth.`
                    },
                    {
                        id: 3, heading: "", b: "Strengthening Bonding:", content: `Breastfeeding fosters a strong emotional bond between mother and child, providing both with comfort and security.`
                    },
                    {
                        id: 4, heading: "", b: "Lowering Health Risks for Mothers:", content: `For mothers, breastfeeding reduces the risk of developing breast and ovarian cancers, type 2 diabetes, and postpartum depression.`
                    },
                ]
            },
            {
                id: 4, subImages: "", subheading: `World Breastfeeding Week 2024: "Enabling Breastfeeding: Making a Difference for Working Parents"`,
                para: `This year's World Breastfeeding Week theme focuses on enabling breastfeeding for working parents. Many parents face challenges in continuing breastfeeding when they return to work. Creating 
                supportive environments at workplaces can make a significant difference in promoting breastfeeding, ultimately leading to healthier families and communities.` ,
            },
            {
                id: 5, subImages: "", subheading: "How SP Medifort Hospital Supports Breastfeeding Mothers", para: `At SP Medifort Hospital, we are committed to supporting breastfeeding mothers through various initiatives:`,
                subData: [
                    { id: 1, b: "Lactation Support Services:", content: "Our experienced lactation consultants offer personalized guidance and support for new mothers, helping them establish and maintain successful breastfeeding routines." },
                    {
                        id: 2, b: "Breastfeeding Education:", content: `We provide educational sessions for expectant and new mothers on the importance of breastfeeding and techniques to overcome common challenges.`
                    },
                    {
                        id: 3, b: "Breastfeeding-Friendly Facilities:", content: `Our hospital is equipped with dedicated breastfeeding rooms that offer a comfortable and private space for mothers to nurse their babies.`
                    },
                ]
            },
            {
                id: 6, subImages: "", subheading: "Tips for Working Parents", para: `For working parents, balancing career responsibilities and breastfeeding can be challenging. Here are a few tips to help:`,
                subData: [
                    { id: 1, b: "Plan Ahead:", content: "Before returning to work, discuss your breastfeeding needs with your employer and create a plan that includes breaks for nursing or pumping." },
                    {
                        id: 2, b: "Express Milk:", content: `Invest in a quality breast pump and practice expressing milk before heading back to work. Store expressed milk properly to ensure your baby continues to receive the benefits of breast milk while you're at work.`
                    },
                    {
                        id: 3, b: "Stay Informed:", content: `Know your rights as a breastfeeding parent in the workplace. Many countries have laws that protect your right to breastfeed or pump during work hours.`
                    },
                ]
            },
            {
                id: 7, subheading: "Conclusion", para: `At SP Medifort Hospital, we believe that breastfeeding is a powerful investment in a healthier future for both mother and child. This World Breastfeeding Week, let’s come together to empower mothers, support working parents, and create environments that make breastfeeding possible for all.`
            },
            {
                id: 8, para: `If you have any questions or need support, our team at SP Medifort Hospital is here to help. Together, let’s prioritize breastfeeding and nurture the next generation for a healthier tomorrow.`
            },

        ]
    },
    {
        id: "@SP_medifort_Blog_1",
        banner: banner1,
        icon: cover,
        title: "Days of Health in June:",
        postDate: "05/06/2024",
        category: "Health",
        previewTitle: `Days of Health in June:`,
        author: "SP Medifort",
        content: [
            {
                id: 1, subheading: "1. 2Alzheimer's & Brain Awareness Month:", para: `Alzheimer's disease is a progressive neurodegenerative disorder characterized by the
                accumulation of abnormal proteins in the brain, leading to memory loss, cognitive
                decline, and ultimately, the inability to carry out daily tasks. It affects millions worldwide
                and is the leading cause of dementia in older adults. While there's no cure, early
                diagnosis and management strategies can help improve quality of life for those affected.
                Ongoing research aims to uncover new treatments and preventive measures for this
                challenging condition.` },
            {
                id: 2, para: `Whatever your age, now is the right time to take actions that help promote a healthy
                brain. Get familiar with brain health and learn what steps you can take if you or someone
                you love has memory concerns.` },
            {
                id: 3, para: `Incorporate some or all of these 10 Healthy Habits into your life and share them with
                someone you know`,
                subData: [
                    { id: 1, heading: "Challenge your mind", content: "Be curious! Put your brain to work and do something that is new for you." },
                    {
                        id: 2, heading: "Stay in school", content: `Education reduces the risk of cognitive decline and dementia. Encourage youth
                        to stay in school and pursue the highest level of training possible.`
                    },
                    {
                        id: 3, heading: "Get moving", content: `Engage in regular exercise. This includes activities that raise your heart rate and
                        increase blood flow to the brain and body.`
                    },
                    {
                        id: 4, heading: "Protect your head", content: `Help prevent an injury to your head. Wear a helmet for activities like biking, and
                        wear a seatbelt. Protect yourself while playing sports. Do what you can to prevent
                        falls, especially for older adults.`
                    },
                    {
                        id: 5, heading: "Be smoke-free", content: `Quitting smoking can lower the risk of cognitive decline back to levels similar to
                        those who have not smoked. It's never too late to stop..`
                    },
                    {
                        id: 6, heading: "Control your blood pressure", content: `Medications can help lower high blood pressure. And healthy habits like eating
                        right and physical activity can help, too. Work with a health care provider to
                        control your blood pressure.`
                    },
                    {
                        id: 7, heading: "Manage diabetes", content: `Type 2 diabetes can be prevented or controlled by eating healthier, increasing
                        physical activity and taking medication, if necessary.`
                    },
                    {
                        id: 8, heading: "Eat right", content: `Eating healthier foods can help reduce your risk of cognitive decline. This
                        includes more vegetables and leaner meats/proteins, along with foods that are
                        less processed and lower in fat. Choose healthier meals and snacks that you
                        enjoy and are available to you.`
                    },
                    {
                        id: 9, heading: "Maintain a healthy weight", content: `Talk to your health care provider about the weight that is healthy for you. Other
                        healthy habits on this list include - eating right, exercising and sleeping well can
                        help with maintaining a healthy weight.`
                    },
                    {
                        id: 10, heading: "Sleep well", content: `Good quality sleep is important for brain health. Stay off screens before bed and
                        make your sleep space as comfortable as possible.`
                    },
                ]
            },
            {
                id: 4, subImages: "", subheading: "2. Cataract Awareness Month", para: `Cataract is the world’s leading cause of blindness, accounting for approximately 42
                percent of all cases of blindness in all nations. A cataract is a clouding of the eye’s lens,
                which blocks or changes the passage of light into the eye. The lens of the eye is located
                behind the pupil and the colored iris, and is normally transparent. Vision may become
                blurry or dim because the cataract stops light from properly passing through to the retina.
                Generally, a cataract does not cause pain, redness or tears.` ,
                subData: [
                    { id: 1, heading: "Risk factors for cataracts include:", content: "Older age" },
                    { id: 2, content: `Intense heat or long-term exposure to UV rays from the sun` },
                    { id: 3, content: `Certain diseases, such as diabetes` },
                    { id: 4, content: `Inflammation in the eye` },
                    { id: 5, content: `Hereditary influences` },
                    { id: 6, content: `Events before birth, such as German measles in the mother` },
                    { id: 7, content: `Long-term steroid use` },
                    { id: 8, content: `Eye injuries` },
                    { id: 9, content: `Eye diseases` },
                    { id: 10, content: `Smoking` },
                ]
            },
            {
                id: 5, subImages: "", subheading: "3. 14 June is World Blood Donor Day", para: `World Blood Donor Day is celebrated annually on June 14th to raise awareness about
                the importance of blood donation and to thank voluntary blood donors for their life-saving
                contributions.`},
            {
                id: 6, subImages: "", para: `Blood donors play a crucial role in ensuring an adequate supply of safe blood for
                transfusions, which are vital for medical emergencies, surgeries, and treatments for
                various conditions. This day serves as a reminder of the significant impact that each
                blood donation can have on saving lives and improving health outcomes. It also
                encourages more people to become regular blood donors, ultimately helping to meet the
                ongoing demand for blood transfusions worldwide.` },
            {
                id: 7, subImages: "", subheading: "4. Building Relationships:", para: `Just like a good salesperson, your website doesn't just focus on the immediate sale. It can
                nurture relationships with potential customers through email marketing opt-ins, informative blog
                posts, and engaging social media integration. This fosters brand loyalty and keeps your
                company top-of-mind, making it more likely that visitors will convert into repeat customers.` },
            {
                id: 8, subheading: "5. National Dengue Awareness Day", para: `National Dengue Awareness Day is observed on June 15th and is aimed at educating
                people about the prevention and control of dengue fever, a mosquito-borne viral infection
                prevalent in tropical and subtropical regions. Dengue fever can cause a wide range of
                symptoms, from mild flu-like illness to severe complications such as dengue
                hemorrhagic fever or dengue shock syndrome, which can be life-threatening.`,
                subData: [
                    {
                        id: 1, heading: "Symptoms:", SubContent: [
                            { id: 1, content: "High fever" },
                            { id: 2, content: "Severe headache" },
                            { id: 3, content: "Pain behind the eyes" },
                            { id: 4, content: "Joint and muscle pain" },
                            { id: 5, content: "Rash" },
                            { id: 6, content: "Mild bleeding" },
                        ]
                    },
                    {
                        id: 2, heading: "Preventive measures include:", SubContent: [
                            { id: 1, content: "Using mosquito repellents" },
                            { id: 2, content: "Wearing protective clothing" },
                            { id: 3, content: "Implementing community-based mosquito control programs" },
                        ]
                    },
                ]
            },
            {
                id: 9, para: `Early detection and prompt medical care are crucial in managing dengue cases
                effectively and preventing further transmission.`
            },
            {
                id: 10, subheading: "6. International Day of Yoga", para: `International Day Of Yoga is observed on June 21st every year. It is celebrated globally
                and promoted extensively in India to highlight the benefits of yoga for physical, mental,
                and spiritual well-being. These observances reflect India's ongoing efforts to address
                public health challenges and promote healthier lifestyles among its citizens.`
            },
            {
                id: 11, para: `The day aims to raise awareness about the benefits of practicing yoga and promote
                global harmony and well-being. Yoga, an ancient practice originating from India,
                encompasses physical, mental, and spiritual disciplines that aim to achieve balance and
                harmony within oneself and with the surrounding world. On this day, people worldwide
                participate in yoga sessions, workshops, and other events to celebrate the practice's
                holistic benefits for health and wellness.`
            },
        ]
    },
]