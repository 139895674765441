import React from "react";
import QuickCard from "./QuickCard";
import "./QuickCard.css";
// import { content } from "./content";
import { SlCalender } from "react-icons/sl";
import { FaUserDoctor } from "react-icons/fa6";
import { MdScreenSearchDesktop } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { GiTestTubes } from "react-icons/gi";

const QuickLink = () => {
  const { t } = useTranslation();
  const words = t("quickLink.todoToday")?.split(" ");
  const firstPart = words.slice(0, words.length - 1).join(" ");
  const secondPart = words[words.length - 1];

  const content = [
    {
      id: 1,
      icon: <SlCalender size={35} />,
      title: t("quickLink.bookAppointment"),
      link:"/our-team"
    },
    {
      id: 2,
      icon: <FaUserDoctor size={35} />,
      title: t("quickLink.bookHealthCheckup"),
      link:`/preventive?testData=${"test"}`
    },
    {
      id: 3,
      icon: <MdScreenSearchDesktop size={35} />,
      title: t("quickLink.BookOnlineConsultation"),
      link:"/our-team"
    },
    {
      id: 4,
      icon: <GiTestTubes  size={35} />,
      title: t("quickLink.ScheduleLabTest"),
      link:"/contact-us"
    },
  ];

  return (
    <div
      className="max-w-common md:mx-auto mx-1 py-32 max-md:py-16 relative"
      id="quick-link"
    >
      <div>
        <h1 className="font-bold text-2xl md:text-headings font-sans">
          {firstPart} <span className="text-primary_color">{secondPart}</span>
        </h1>
      </div>
      <img
        className="absolute h-[350px] max-md:h-[280px] -right-20 max-md:-right-0 -bottom-24 max-md:-bottom-20  -z-10 animate-spin"
        style={{ animationDuration: "30s" }}
        src={require("../../../assets/logo/moving_logo.png")}
        alt=""
      />
      <div className="flex flex-col md:flex-row gap-5 items-center md:justify-center mt-10 z-10 max-md:grid max-md:grid-cols-2 max-md:mx-2">
        {content?.map((items, index) => (
          <QuickCard key={index} data={items} index={index} />
        ))}
      </div>
      <div className="absolute  left-0 right-0 mx-auto  moving-logo1 opacity-40 bottom-6 -z-10 max-md:hidden">
        <img
          className="object-contain  h-[150px]  animate-spin  max-md:hidden"
          style={{ animationDuration: "60s" }}
          src={require("../../../assets/logo/moving_logo.png")}
          alt=""
        />
      </div>
      <div className="absolute  right-0 -top-20  moving-logo2 opacity-40 bottom-6 -z-10 max-md:hidden">
        <img
          className="object-contain  h-[120px]  animate-spin  max-md:hidden"
          style={{ animationDuration: "60s" }}
          src={require("../../../assets/logo/moving_logo.png")}
          alt=""
        />
      </div>
    </div>
  );
};

export default QuickLink;
