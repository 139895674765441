import React from 'react'
import HeadingPart from '../../common/HeadingPart'

const FlipBook = () => {
    return (
        <div className='max-w-common mx-auto p-3 py-10 md:pt-20'>
            {/* Heading part */}
            <HeadingPart title={"ebook.title"} subTitle={''} />

            <div className='mt-10'>
                <iframe src="https://online.fliphtml5.com/mjqck/wnkg/#p=1"
                    title="Iframe Example"
                    className='w-full h-[90vh] rounded-xl'
                />
            </div>
        </div>
    )
}

export default FlipBook
