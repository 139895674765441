import React from "react";
import { Link } from "react-router-dom";

const ServiceRelatedBlogCard = ({ title, date, catrgory, image, data }) => {
  return (
    <div className="w-[300px] h-[330px] bg-[#EDEFF3] rounded-lg overflow-hidden">
      <Link to={`/news/${data?.id}`}>
        <img className="w-full h-[200px] object-cover" src={image} alt="" />
      </Link>
      <div className="w-full p-5">
        <div className="flex gap-x-2 text-sm mb-2 text-primary_color">
          <p>{date} </p>|<p> {catrgory}</p>
        </div>
        <h1 className="text-sm font-medium text-primary_color text-left leading-5">
          {title}
        </h1>
      </div>
    </div>
  );
}

export default ServiceRelatedBlogCard;
