import React from 'react'
import { useTranslation } from 'react-i18next';

const HeadingPart = ({ title, subTitle }) => {
    const { t } = useTranslation();
    const words = t(title)?.split(" ");
    const firstPart = words.slice(0, words.length - 1).join(" ");
    const secondPart = words[words.length - 1];
    return (
        <div className="flex flex-col items-start max-md:items-center max-md:mb-6">
            <h1 className="font-bold text-2xl md:text-headings font-sans">
                {firstPart}
                <span className="text-primary_color"> {secondPart}</span>
            </h1>
            <h6 className="mt-2 md:text-xl font-newFont font-thin">
                {t(subTitle)}
            </h6>
        </div>
    )
}

export default HeadingPart
