import React, { useEffect, useState } from "react";
import logo from "../../assets/logo/Logo.svg";
import "../../components/doctor/DoctorProfile.css";
import bgImage from "../../assets/doctors/bg.png";
import axios from "../../api/axios";
import emailjs from "@emailjs/browser";
import BookAppoinment from "../../components/doctor/BookAppoinment";
import { useParams } from "react-router-dom";
import { doctors } from "../../components/home/department/departmentData";
import { dateWiseDocVisit, getTokensRoute } from "../../utils/Endpoint";
import { toast } from "react-toastify";
import { Box, Modal, TextField } from "@mui/material";

function DoctorProfile() {
  const [docData, setDocData] = useState({});
  const { id } = useParams();

  // After the API Integration pls remove the modal part
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);

  const ServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const TemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const PublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
  // -----------------------end--------------------------------

  const department_id = "5dc1bd612dbcc43ee02263db";
  const doctor_id = "5d70e2d62dbcc447147ffd02";

  const tommorrowDate = () => {
    const d = new Date();
    d.setDate(d.getDate() + 1);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();

    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const data = doctors.find((items) => items?.id == id);
    console.log(data);
    setDocData(data);
  }, [id]);

  const [fDates, setFDates] = useState([]);
  const [bookDate, setBookDate] = useState(tommorrowDate());
  const [visits, setVisits] = useState([]);
  const [visitId, setVisitId] = useState(null);
  const [tokens, setTokens] = useState([]);
  const [tokenId, setTokenId] = useState(null);

  const getNextWeekDates = () => {
    const dates = [];
    const today = new Date();

    for (let i = 1; i <= 5; i++) {
      const nextDay = new Date(today);
      nextDay.setDate(today.getDate() + i);
      dates.push(nextDay);
    }

    setFDates([...dates]);
  };

  useEffect(() => {
    getNextWeekDates();
  }, []);

  const DateWiseVisit = async () => {
    const response = await axios.post(
      `${dateWiseDocVisit}?department_id=${department_id}&doctor_id=${doctor_id}`,
      { data: { date: bookDate } }
    );

    if (response) {
      const visits = response?.data?.data[0]?.visits;
      console.log(response?.data);
      visits?.length && setVisits([...visits]);
    }
  };

  // useEffect(() => { DateWiseVisit() }, [bookDate])

  const FetchTokens = async () => {
    const response = await axios.post(getTokensRoute, {
      data: { visit_id: visitId },
    });

    if (response) {
      const tokens = response?.data?.data;
      setTokens([...tokens]);
    }
  };

  // useEffect(() => { FetchTokens() }, [visitId])
  // After the API Integration pls remove the modal part
  useEffect(() => {
    emailjs.init(PublicKey);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "Doctor Booking",
    message: `
    Doctor Name: ${docData?.name || "N/A"},
    Department: ${docData?.department || "N/A"},
    `,
  });

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      message: `
        Doctor Name: ${docData?.name || "N/A"},
        Department: ${docData?.department || "N/A"},
      `,
    }));
  }, [docData]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const nameRegex = /^[a-zA-Z\s'-]{2,50}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const mobileNumberRegex = /^[6-9]\d{9}$/;

    if (!nameRegex.test(formData?.name)) {
      return toast.info('Enter a valid name')
    }

    if (!emailRegex.test(formData?.email)) {
      return toast.info('Enter a valid email')
    }

    if (!mobileNumberRegex.test(formData?.phone)) {
      return toast.info('Enter a valid Phone number')
    }

    console.log(formData);
    setLoading(true);

    emailjs
      .sendForm(ServiceId, TemplateId, e.target, PublicKey)
      .then((result) => {
        console.log("Email sent successfully!");

        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });

        toast.success("Message Send Successfully");
        setLoading(false);
        setOpen(false);
        console.log("Form submitted:", result);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        toast.error("Please try after sometime");
        setLoading(false);
      });
  };

  // for the modal
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    width: 500,
    borderRadius: 2,
    p: 4,
    "@media (max-width: 768px)": {
      width: "90vw",
    },
  };

  return (
    <div className="max-w-common mx-auto w-full p-3 md:p-2">
      <div className="mt-36 md:mt-48 relative flex flex-col md:flex-row gap-x-14">
        <img src={logo} alt="" className="absolute right-0 top-0 md:scale-85" />
        <div className="w-full md:w-3/12  flex flex-col items-start ">
          <div
            className="rounded-lg w-full"
            style={{
              backgroundImage: `url(${bgImage})`,
            }}
          >
            <img
              src={docData?.Image}
              alt=""
              className="h-[200px] w-full object-top object-cover  rounded-lg"
            />
          </div>
          <div className="flex flex-col items-start justify-between w-full mt-3">
            <h1 className=" font-medium md:font-semibold text-primary_color text-xl">
              {docData?.name}

              {docData?.opd_timings?.length > 0 && (
                <p className="opacity-75 text-sm font-normal mt-1  text-black text-start ">
                  OPD timings
                </p>
              )}
            </h1>

            {docData?.opd_timings?.map((item, i) => (
              <div key={i} className="w-full flex items-center justify-between">
                <p className="text-lg text-primary_color font-semibold">
                  {item?.days}
                </p>
                <p className="text-lg ">{item?.time}</p>
              </div>
            ))}
          </div>

          <div onClick={handleOpen} className="mb-10 md:mb-0 mt-5  w-full">
            <button className="border-none w-full px-5 py-3 rounded text-sm text-white bg-primary_color font-medium hover:scale-105 ease-in-out duration-300">
              Book an appointment
            </button>
          </div>
        </div>

        <div className="w-full md:w-9/12 flex flex-col items-start gap-y-7">
          <div className="flex flex-col items-start gap-y-2">
            <h1 className="text-mainHeading text-primary_color font-medium">
              {docData?.name}
            </h1>
            <h2 className="text-xl">{docData?.qualification}</h2>
            <p className="text-left text-2xl font-light opacity-70">
              {docData?.title}—
              <br />
              {docData?.department}
            </p>
          </div>

          {/* <div className="flex flex-col items-start w-full ">
            <h2 className="text-3xl font-normal">About</h2>
            <div className="h-[2px] w-full bg-primary_color"></div>
            <p className="md:mr-48 text-left text-lg text-[#6D6E71] pt-3">
              Dr Cherian M Thomas is an experienced orthopaedic surgeon in
              Mumbai, with over 32 years of experience overall. He specialises
              in hip and knee replacement surgeries, foot injury treatment, and
              lower extremity wound care. His extensive experience has made him
              one of the best orthopaedic surgeons in Mumbai.
            </p>
          </div> */}

          <div className="flex flex-col items-start w-full">
            <h2 className="text-3xl font-normal">Experience</h2>
            <div className="h-[2px] w-full bg-primary_color"></div>
            <ul className="inline-block text-left text-lg text-[#6D6E71] exp-list pt-3">
              {docData?.experiences?.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col items-start w-full">
            <h2 className="text-3xl font-normal">Areas of Expertise</h2>
            <div className="h-[2px] w-full bg-primary_color"></div>
            <ul className="inline-block text-left text-lg text-[#6D6E71] aoe-list pt-3">
              {docData?.areas_of_expertise?.map((item, i) => (
                <li className="py-1" key={i}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row w-full mt-20" id="booking">
        {/* <div className="md:w-3/12 flex flex-col md:items-start mb-5 md:mb-0">
          <h1 className="text-2xl font-medium md:font-semibold text-primary_color pb-2">
            Book Hospital Visit
          </h1>
          <p className="text-sm text-[#6D6E71]">
            Available tomorrow at 10:00 AM
          </p>
          <p className="text-3xl font-medium">₹1250</p>
          <p className="pt-5 text-sm text-[#6D6E71] mb-3">
            Select date and time
          </p>
          <div className="w-full md:w-1/2">
            <BookAppoinment />
          </div>
        </div> */}
        {/* 
        <div className="md:w-9/12 border border-black rounded-xl flex h-fit overflow-clip">
          {!visitId && (
            <div className="w-2/12 grid grid-cols-1">
              {fDates?.map((item, i) => (
                <DateCard
                  key={i}
                  day={item.toString().split(" ")[0]}
                  month={item?.toString().split(" ")[1]}
                  num={item?.toString().split(" ")[2]}
                  date={item}
                  bookDate={bookDate}
                  setBookDate={setBookDate}
                />
              ))}
            </div>
          )}

          <div className="w-full flex items-center flex-col p-5">
            {visitId ? (
              <div className="w-full flex items-center justify-between flex-col gap-y-2 relative ">
                <h2 className="text-xl ">Choose a Token</h2>
                <h2 className="text-lg  text-gray-500">Date : {bookDate}</h2>
                <hr className="h-[1.5px] w-full bg-black mt-2 mb-4" />
                <div className="flex flex-wrap justify-center sm:justify-between gap-6 sm:gap-3 w-full">
                  {tokens?.length > 0 ? (
                    tokens?.map((item, i) => (
                      <TokenCard
                        key={i}
                        data={item}
                        tokenId={tokenId}
                        setTokenId={setTokenId}
                      />
                    ))
                  ) : (
                    <h2 className="w-full self-center">No Slots Available</h2>
                  )}
                </div>

                <button
                  onClick={() => setVisitId(null)}
                  className="absolute top-4 left-0 text-primary_color border border-primary_color p-2 rounded-lg"
                >
                  Go Back
                </button>
              </div>
            ) : (
              <div className="w-full h-full flex flex-col gap-5 justify-around">
                <h1 className="text-xl">Choose a Session</h1>

                <div className="w-full h-full flex flex-wrap items-center justify-around gap-4 ">
                  {visits?.length > 0 &&
                    visits?.map((item, i) => (
                      <div
                        onClick={() => setVisitId(item?.visit_id)}
                        key={i}
                        className="w-fit h-fit flex flex-col cursor-pointer border border-primary_color rounded-lg p-5"
                      >
                        <span>Session {i + 1}</span>
                        <span>
                          {" "}
                          {item.time_from} to {item?.time_to}{" "}
                        </span>
                        <span>Available Slots : {item?.slots_available} </span>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div> */}
      </div>

      {
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="flex flex-col ">
              <h4 className="text-sm font-medium text-primary_color">
                Booking Info
              </h4>
              <p className="text-lg  font-medium">
                Please enter your details to finish booking{" "}
              </p>
              <form
                onSubmit={handleSubmit}
                className="flex flex-col gap-y-4 mt-5"
              >
                <TextField
                  size="small"
                  id="outlined-search"
                  label="Full name"
                  type="name"
                  name="name"
                  onChange={handleChange}
                  value={formData?.name}
                />
                <TextField
                  size="small"
                  id="outlined-search"
                  label="Email"
                  type="email"
                  name="email"
                  onChange={handleChange}
                  value={formData?.email}
                />
                <TextField
                  size="small"
                  id="outlined-search"
                  label="Phone Number"
                  type="phone"
                  name="phone"
                  onChange={handleChange}
                  value={formData?.phone}
                />
                {/* Hidden input for the message */}
                <input type="hidden" name="message" value={formData?.message} />
                <button className="border-none px-5 py-2 rounded-lg text-sm text-white bg-primary_color  w-fit">
                  Submit
                </button>
              </form>
              <div className="h-[1px] bg-gray-700 w-full my-5"></div>
              <a
                onClick={() => setOpen(false)}
                href="tel: +91 0471 3100 100"
                className="w-full"
              >
                <button className="border-none w-full px-4 py-3 rounded-lg text-sm text-white bg-primary_color font-medium">
                  <span className=""> Call - 471 3100 100</span>
                </button>
              </a>
            </div>
          </Box>
        </Modal>
      }
    </div>
  );
}

export default DoctorProfile;

const DateCard = (props) => {
  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const isActive = props.bookDate === formatDate(props?.date);

  return (
    <div
      onClick={() => props.setBookDate(formatDate(props.date))}
      className={`flex flex-col items-center w-full  hover:bg-white hover:text-primary_color 
      cursor-pointer hover:ease-in-out duration-300   text-sm py-2 ${isActive
          ? "bg-white text-primary_color"
          : "bg-primary_color text-white border-b"
        } `}
    >
      <p className="uppercase">{props.day}</p>
      <h5 className="text-2xl">{props.num}</h5>
      <p className="uppercase">{props.month}</p>
    </div>
  );
};

const TokenCard = ({ data, tokenId, setTokenId }) => {
  const isChosen = tokenId === data?.id;

  return (
    <div
      onClick={() => setTokenId(data?.id)}
      className={` hover:bg-primary_color hover:text-white 
    cursor-pointer rounded-lg px-3 py-1 flex flex-col ${isChosen
          ? "bg-primary_color text-white"
          : "text-primary_color border border-primary_color"
        }`}
    >
      <h3>Token {data?.token}</h3>
      <p className="text-sm ">
        {data?.time_from} to {data?.time_to}
      </p>
    </div>
  );
};
