import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import banner2 from "../../../assets/landing/Banner2.png";
import banner1 from "../../../assets/landing/Banner1.jpg";
import banner3 from "../../../assets/landing/banner3.jpg";
import banner4 from "../../../assets/landing/banner4.jpg";
import bannerMob2 from "../../../assets/landing/BannerMob2.png";
import bannerMob1 from "../../../assets/landing/BannerMob1.jpg";
import bannerMob3 from "../../../assets/landing/bannerMob3.jpg";
import bannerMob4 from "../../../assets/landing/BannerMob4.png";

const LandingPage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const slides = [
    { type: "image", src: banner2 },
    { type: "image", src: banner1 },
    { type: "image", src: banner3 },
    { type: "image", src: banner4 },
  ];

  const mobSlides = [
    { type: "image", src: bannerMob2 },
    { type: "image", src: bannerMob1 },
    { type: "image", src: bannerMob3 },
    { type: "image", src: bannerMob4 },
  ];

  const currentSlides = isMobile ? mobSlides : slides;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 4000);
    return () => clearInterval(interval);
  }, [currentSlide]);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === currentSlides.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? currentSlides.length - 1 : prevSlide - 1
    );
  };

  return (
    <div className="relative h-[430px] lg:h-screen w-full overflow-hidden">
      <div className="relative w-full h-full">
        <div className="flex transition-transform duration-500 ease-in-out transform"
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
          {currentSlides.map((slide, index) => (
            <div
              key={index}
              className="flex-none w-full h-full mt-32"
              style={{ backgroundImage: `url(${slide.src})` }}
            >
              {slide.type === "image" && (
                <img
                  src={slide.src}
                  alt={`Slide ${index}`}
                  className="object-cover w-full h-full"
                />
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="absolute inset-y-1/2 left-0 flex justify-between w-full px-5 -translate-y-1/2">
        <button
          onClick={prevSlide}
          className="text-white text-3xl hover:text-gray-300"
        >
          <IoIosArrowBack />
        </button>
        <button
          onClick={nextSlide}
          className="text-white text-3xl hover:text-gray-300"
        >
          <IoIosArrowForward />
        </button>
      </div>
    </div>
  );
};

export default LandingPage;
