import React, { useEffect, useState } from "react";
import image from "../assets/preventive/banner.png";
import TopBanner from "../components/common/TopBanner";
import Awareness from "../components/preventiveHealth/Awareness";
import Test from "../components/preventiveHealth/Test";
import { FaSyringe } from "react-icons/fa";
import { GiAwareness } from "react-icons/gi";
import { SiMicrogenetics } from "react-icons/si";
import { GiTestTubes } from "react-icons/gi";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import ModalData from "../components/Modal";

const Preventive = () => {
  const [tab, setTab] = useState("awareness");
  const [searchParams] = useSearchParams();
  const testData = searchParams.get("testData");
  useEffect(() => {
    if (testData) {
      setTab("test");
    }
  }, [testData]);


  return (
    <div className="w-full h-auto flex flex-col items-center gap-5 pb-10 ">
      <TopBanner title="Preventive Health" image={image} />

      <div className="w-full sm:max-w-common mx-auto flex flex-col items-start  overflow-hidden p-3">
        <div className="w-full sm:w-fit flex flex-col sm:flex-row md:items-center gap-5 py-5 md:mb-0 md:py-16 ">
          <div
            style={{
              backgroundImage: `URL( ${require("../assets/preventive/dashcircle.png")})`,
            }}
            className="w-[150px] h-[150px] bg-center bg-contain bg-no-repeat 
                    flex items-center justify-center "
          >
            <span className="">
              {tab === "vaccine" ? (
                <FaSyringe color="white" size={48} />
              ) : tab === "awareness" ? (
                <GiAwareness color="white" size={48} />
              ) : tab === "gene" ? (
                <SiMicrogenetics color="white" size={48} />
              ) : (
                tab === "test" && <GiTestTubes color="white" size={48} />
              )}
            </span>
          </div>

          <div className="w-full sm:w-fit min-w-fit h-auto flex flex-col  gap-5 sm:gap-2 select-none">
            <h1 className="text-primary_color font-bold text-4xl text-start  md:mb-3">
              Overview
            </h1>
            <div className="w-full flex flex-col sm:flex-row items-center gap-3 sm:gap-2">
              <button
                onClick={() => setTab("vaccine")}
                className={`w-full sm:w-fit h-[40px]
                            ${tab === "vaccine"
                    ? "  bg-primary_color text-white"
                    : "border border-primary_color text-primary_color"
                  }
                             py-1 px-5
                            rounded-md text-sm flex items-center justify-center `}
              >
                Vaccines
              </button>

              <button
                onClick={() => setTab("awareness")}
                className={`w-full sm:w-fit h-[40px] 
                            ${tab === "awareness"
                    ? "  bg-primary_color text-white"
                    : "border border-primary_color text-primary_color"
                  } 
                            py-1 px-5
                            rounded-md text-sm flex items-center justify-center`}
              >
                Public Health Awareness
              </button>

              <button
                onClick={() => setTab("gene")}
                className={`w-full sm:w-fit h-[40px] 
                            ${tab === "gene"
                    ? "  bg-primary_color text-white"
                    : "border border-primary_color text-primary_color"
                  }
                            py-1 px-5
                            rounded-md text-sm flex items-center justify-center`}
              >
                Genetic Study
              </button>

              <button
                onClick={() => setTab("test")}
                className={`w-full sm:w-fit h-[40px] 
                            ${tab === "test"
                    ? "  bg-primary_color text-white"
                    : "border border-primary_color text-primary_color"
                  }
                            py-1 px-5
                            rounded-md text-sm flex items-center justify-center`}
              >
                Screening and Tests
              </button>
            </div>
          </div>
        </div>
        <div className="w-full">
          {tab === "awareness" ? <Awareness /> : tab === "test" && <Test />}
        </div>
      </div>

      {/* button */}
      <div className="w-full sm:max-w-common mx-auto flex p-3 items-center">
        <a href="/health.pdf" target="_blank" rel="noopener noreferrer" className="focus:none">
          <button className="border p-3 px-10 bg-primary_color text-white rounded-full">Download Health Checks Brochure</button>
        </a>
      </div>

    </div>
  );
};

export default Preventive;
