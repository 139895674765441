const { department } = require('../components/home/department/departmentData')
const { Specialty } = require('../data/Specialty')

const deptList = department.map((item, i) => (
    { id: item?.id, name: item?.title, link: `/departments/detailed-view/${item?.id}` }
))

const splList = Specialty.map((item, i) => (
    { id: item?.id, name: item?.Heading, link: `/specialties#${item?.id}` }
))

export const footlist = [
    {
        listId: 0,
        title: 'Quick Links',
        sub: [
            { id: 1, name: 'About US', link: "/about-us" },
            { id: 2, name: 'Leadership', link: "/#leadership" },
            { id: 3, name: 'Careers', link: "/join-us" },
            { id: 4, name: 'Contact Us', link: "/contact-us" },
            { id: 5, name: 'Emergency 24/7', link: "/emergency" },
            { id: 6, name: 'Our Doctors', link: "/our-team" },
            { id: 7, name: 'Latest News', link: "/news" },
            { id: 8, name: 'Gallery', link: "/gallery" },
            { id: 7, name: 'Our Specialties', link: "/specialties" },
            { id: 8, name: 'Departments', link: "/departments" },
            { id: 12, name: 'Insurance', link: "/insurance" },
            // { id: 9, name: 'Our Hospitals', link: "/hospitals" },
            // { id: 13, name: 'Knee Implant Pricing', link: "/preventive" },
            // { id: 10, name: 'Follow us on Social Media Handles', link: "/" },
            // { id: 11, name: 'Download app', link: "/" },
        ]
    },
    {
        listId: 1,
        title: 'Patient Care',
        sub: [
            { id: 1, name: 'Find A Doctor', link: "/our-team" },
            { id: 2, name: 'Medical Services', link: "/diagnostics" },
            { id: 3, name: 'Patient Testimonials', link: "/#testimonials" },
            // { id: 4, name: 'Value Added Services', link: "/diagnostics" },
            // { id: 5, name: 'Pay Online', link: "/home" },
            // { id: 6, name: 'SP Surgery', link: "/home" },
        ]
    },
    {
        listId: 3,
        title: 'News & Media',
        sub: [
            { id: 1, name: 'News', link: "/news" },
            // { id: 2, name: 'Events', link: "/home" },
            // { id: 3, name: 'Interviews', link: "/home" },
            // { id: 4, name: 'Watch Our Videos', link: "/corporate" },
            // { id: 5, name: 'Media Contacts', link: "/contact-us" },
            // { id: 6, name: 'Sp Hospitals Reviews', link: "/home" },
        ]
    },
    {
        listId: 4,
        title: 'Departments',
        sub: deptList
        // sub: [
        //     { id: 1, name: 'Orthopaedics', link: "/services" },
        //     { id: 2, name: 'Nephrology & Urology', link: "/services" },
        //     { id: 3, name: 'Bariatric Surgery', link: "/services" },
        //     { id: 4, name: 'Cardiology', link: "/home" },
        //     { id: 5, name: 'Pulmonology', link: "/home" },
        //     { id: 6, name: 'Gastroenterology', link: "/home" },
        //     { id: 7, name: 'Spine Surgery', link: "/home" },
        //     { id: 8, name: 'Oncology', link: "/home" },
        //     { id: 9, name: 'Neurology & Neurosurgery', link: "/home" },
        //     { id: 10, name: 'Organ Transplantation', link: "/home" },
        //     { id: 11, name: 'Robotic Surgery', link: "/home" },
        //     { id: 12, name: 'Preventive Health Check Up', link: "/home" },
        //     { id: 13, name: 'SP ProHealth', link: "/home" },
        //     { id: 14, name: 'Emergency Care', link: "/home" },
        //     { id: 15, name: 'Ear,Nose And Throat', link: "/home" },
        //     { id: 16, name: 'Vascular Surgery', link: "/home" },
        //     { id: 17, name: 'Pediatrics', link: "/home" },
        //     { id: 18, name: 'Dermatology', link: "/home" },
        //     { id: 19, name: 'Obstetrics And Gynaecology', link: "/home" },
        // ]
    },
    {
        listId: 5,
        title: 'Specialties',
        sub: splList
    },
    // {
    //     listId: 5,
    //     title: 'Medical Procedures',
    //     sub: [
    //         { id: 1, name: 'Proton Therapy For Cancer Treatment', link: "/home" },
    //         { id: 2, name: 'Cosmetic And Plastic Surgery', link: "/home" },
    //         { id: 3, name: 'Bone Marrow Transplant', link: "/home" },
    //         { id: 4, name: 'Oral & Maxillofacial Surgery', link: "/home" },
    //         { id: 5, name: 'Hand MicroSurgery', link: "/home" },
    //         { id: 6, name: 'G Scan – Open Standing MRI Scan', link: "/home" },
    //         { id: 7, name: 'Hip Arthroscopy', link: "/home" },
    //         { id: 8, name: 'Minimally Invasive Cardiac Surgery', link: "/home" },
    //         { id: 9, name: 'Knee Replacement Surgery', link: "/home" },
    //         { id: 10, name: 'Cochlear Implant Surgery', link: "/home" },
    //     ]
    // },
    // {
    //     listId: 6,
    //     title: 'Blogs',
    //     sub: [
    //         { id: 1, name: 'Health Library', link: "/home" },
    //         { id: 2, name: 'Covid 19 Updates', link: "/home" },
    //         { id: 3, name: 'Covid 19 FAQS', link: "/home" },
    //         { id: 4, name: 'COVID-19 Vaccination', link: "/home" },
    //         { id: 5, name: 'COVID-19 New Symptoms', link: "/home" },
    //     ]
    // },
    // {
    //     listId: 7,
    //     title: 'Corporate',
    //     sub: [
    //         { id: 1, name: 'Company Overview', link: "/about-us" },
    //         { id: 2, name: 'Our Doctors Achieve', link: "/our-team" },
    //         { id: 3, name: 'The SP Ethos', link: "/home" },
    //         { id: 4, name: 'The SP Story', link: "/home" },
    //         { id: 5, name: 'Management', link: "/hospitals" },
    //         { id: 6, name: 'Investor Relations', link: "/home" },
    //         { id: 7, name: 'Investor Presentation', link: "/home" },
    //         { id: 8, name: 'Initiatives', link: "/home" },
    //         { id: 9, name: 'Awards & Accolades', link: "/home" },
    //         { id: 10, name: 'Careers', link: "/join-us" },
    //     ]
    // },
    {
        listId: 8,
        title: 'Hospitals',
        sub: [
            { id: 1, name: 'Our Hospitals', link: "/hospitals" },
            // { id: 2, name: 'International Hospitals', link: "/hospitals" },
            // { id: 3, name: 'SP Clinics', link: "/hospitals" },
            // { id: 4, name: 'Reach Hospitals', link: "/hospitals" },
            // { id: 5, name: 'SP Cradle', link: "/hospitals" },
            // { id: 6, name: 'SP Fertility', link: "/hospitals" },
            // { id: 7, name: 'SP Cancer Centers', link: "/hospitals" },
            // { id: 8, name: 'SP Proton Centers', link: "/hospitals" },
        ]
    },
    // {
    //     listId: 9,
    //     title: 'Academics & Research',
    //     sub: [
    //         { id: 1, name: 'Courses', link: "/join-us" },
    //         { id: 2, name: 'Academics', link: "/home" },
    //         { id: 3, name: 'Clinical Research', link: "/home" },
    //         { id: 4, name: 'Honors List', link: "/home" },
    //         { id: 5, name: 'SP Torch: Alumni Network', link: "/home" },
    //         { id: 6, name: 'Web Broadcasts', link: "/home" },
    //         { id: 7, name: 'Collaborative Forums', link: "/home" },
    //         { id: 8, name: 'What’s New', link: "/home" },
    //     ]
    // },
    {
        listId: 10,
        title: 'Contact Us',
        sub: [
            { id: 1, name: 'Post A Query', link: "/contact-us" },
            // { id: 2, name: 'Consult Doctors Online', link: "/home" },
            { id: 3, name: 'Book Physical Appointment', link: "/our-team" },
            // { id: 4, name: 'Give Your Feedback', link: "/home" },
            // { id: 5, name: 'SP Lifeline', link: "/home" },
        ]
    },
]