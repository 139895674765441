import React, { useEffect, useState, useRef } from "react";
import TopBanner from "../components/common/TopBanner";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  department,
  doctors,
  treatments,
} from "../components/home/department/departmentData";
import { useTranslation } from "react-i18next";
import ServiceRelatedBlogCard from "../components/Services/ServiceRelatedBlogCard";
import { Blog } from "../data/Blogs";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import DoctorCard from "../components/home/selectDoctor/DoctorCard";

const DepartmentDetailedView = () => {
  const [data, setData] = useState({
    department: {},
    doctors: [],
    treatments: [],
  });
  const { id } = useParams();
  const { t } = useTranslation();
  const words = t("newsBlogs.title")?.split(" ");
  const firstPart = words.slice(0, words.length - 1).join(" ");
  const secondPart = words[words.length - 1];

  const navigate = useNavigate();

  //scroll
  const scrollContainer = useRef();
  const scrollLeft = () => {
    scrollContainer?.current?.scrollBy({
      left: -383,
      behavior: "smooth",
    });
  };
  const scrollRight = () => {
    scrollContainer?.current?.scrollBy({
      left: 383,
      behavior: "smooth",
    });
  };

  const departmentData = (value) => {
    // window.scrollTo(0, 0);
    const deptData = department.find((items) => items?.id === Number(value));
    const deptDocs = doctors.filter(
      (item) => item.department.toLowerCase() === deptData?.title.toLowerCase()
    );
    const deptTreat = treatments.find(
      (item) => item?.department.toLowerCase() === deptData?.title.toLowerCase()
    );
    setData((prev) => ({
      ...prev,
      department: deptData,
      doctors: deptDocs,
      treatments: deptTreat?.list,
    }));
  };

  useEffect(() => {
    departmentData(id);
  }, [id]);

  console.log(data);

  return (
    <div>
      <TopBanner
        image={data?.department?.Image}
        title={data?.department?.title}
      />
      <div className="flex flex-col md:flex-row mt-10 max-w-[1280px] mx-auto w-full p-2 sm:gap-8">
        {/* Sidebar Desktop*/}
        <div className="hidden sm:block w-full md:w-1/4 h-fit border border-primary_color ">
          <ul className="flex flex-col items-start w-full">
            <li
              className={`w-full p-2 text-white font-medium bg-primary_color cursor-pointer capitalize text-center select-none`}
            >
              Our Departments
            </li>

            {department.map((items) => (
              <>
                <li
                  onClick={(e) =>
                    navigate(`/departments/detailed-view/${items?.id}`)
                  }
                  key={items?.id}
                  className={`${data?.department?.id == items?.id
                    ? "bg-[#f4dfed]"
                    : "bg-white hover:bg-gray-100 hover:border-2 border-l-primary_color"
                    } w-full p-2 pl-6 text-primary_color cursor-pointer capitalize text-left 
                  select-none text-sm `}
                >
                  {items?.title}
                </li>

                <hr className="w-full bg-primary_color" />
              </>
            ))}
          </ul>
        </div>

        {/* Mobile view */}
        <div className="bg-primary_color mb-4 rounded-xl w-full h-[60px] flex sm:hidden justify-around items-center ">
          <select
            onChange={(e) =>
              navigate(`/departments/detailed-view/${e.target.value}`)
            }
            className="w-fit p-2 outline-none rounded-lg"
            value={data?.department?.id}
          >
            <option value="" disabled>
              Select Department
            </option>
            {department.map((item, i) => (
              <option key={i} value={item?.id}>
                {item.title}
              </option>
            ))}
          </select>
        </div>

        <div className="w-full md:w-3/4 flex flex-col items-start sm:ps-5">
          <h1 className="font-bold text-primary_color text-xl md:text-3xl mb-2 md:mb-5">
            Department of {data?.department?.title}
          </h1>

          <div className="w-full h-[260px] mt-4 mb-8 flex justify-between">
            <img
              src={data?.department?.subImg}
              className="w-full h-full object-cover"
              alt="subImag"
            />
          </div>

          {/* Overview */}
          <p className="text-start">{data?.department?.content}</p>

          {data?.department?.list?.length > 0 && (
            <div className="w-full flex flex-col ">
              {data?.department?.list?.map((items, i) => (
                <div className="mt-5" key={i}>
                  <h1 className="text-start text-xl font-semibold text-primary_color">
                    {items?.title}
                  </h1>
                  {items?.list &&
                    items?.list.map((item, i) => (
                      <div className="mt-3 ps-5" key={i}>
                        <h1 className="text-start mb-2 text font-semibold ">
                          {item?.title}
                        </h1>
                        <p className="text-start ">{item?.content}</p>
                      </div>
                    ))}
                </div>
              ))}
            </div>
          )}

          {/* Our Doctors */}
          {data?.doctors?.length > 0 && (
            <div className="w-full flex flex-col">
              <h1 className="font-bold text-primary_color text-left text-xl md:text-3xl mt-4 md:mt-8">
                Our Doctors
              </h1>
              <div className="w-full flex relative">
                <div
                  className="cursor-pointer w-[40px] p-3 h-fit absolute top-[50%] left-3 md:left-14 rounded-full bg-primary_color  text-white outline-none focus:outline-none z-20"
                  onClick={scrollLeft}
                >
                  <IoIosArrowBack />
                </div>
                <div
                  ref={scrollContainer}
                  className="flex w-full overflow-x-scroll pl-10 max-md:px-[25px] hide-scrollbar relative"
                >
                  <div className="w-fit flex mt-2 gap-x-16 md:ps-24">
                    {data?.doctors.slice(0, 10).map((items) => (
                      <DoctorCard
                        key={items?.id}
                        Name={items?.name}
                        department={items?.department}
                        image={items?.Image}
                        data={items}
                      />
                    ))}
                  </div>
                </div>
                <div
                  className="cursor-pointer w-[40px] h-fit absolute top-[50%] right-3 md:right-14 rounded-full bg-primary_color p-3 text-white outline-none z-10"
                  onClick={scrollRight}
                >
                  <IoIosArrowForward />
                </div>
              </div>
            </div>
          )}

          {data?.treatments?.length > 0 && (
            <div className="w-full flex flex-col">
              <h1 className="font-bold text-primary_color text-left text-xl md:text-3xl my-4 md:my-8">
                Treatments & Procedures
              </h1>

              {data?.treatments?.map((item, i) => (
                <div
                  key={i}
                  className="w-full flex flex-col mb-2 sm:mb-4 gap-2"
                >
                  <h3 className="font-bold text-primary_color text-left text-base md:text-xl capitalize">
                    {item?.name}
                  </h3>

                  <p className="text-left">{item?.details}</p>

                  {item?.list && (
                    <ul className="text-start w-full  ps-5">
                      {item?.list.map((item, i) => (
                        <>
                          {item?.heading && (
                            <li className="font-bold -ms-5">
                              <h1 className="font-bold py-2 ">
                                {item?.heading}
                              </h1>{" "}
                            </li>
                          )}
                          <li key={i} className="dot list-disc ">
                            {item?.content}
                          </li>
                        </>
                      ))}
                    </ul>
                  )}
                  {item?.team && (
                    <>
                      <h1 className="font-bold py-2 text-start">Team Members</h1>
                      <div className="text-start flex w-full gap-5">
                        {item?.team.map((item, i) => (
                          <span key={i} className="border border-primary_color rounded-lg text-sm hover:bg-primary_color hover:text-white ease-in-out duration-300 p-2 px-5 ">
                            {item?.name}
                          </span>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Second part */}
      <hr className="w-full h-[1px] bg-primary_color mt-10 md:mt-20" />

      <div className="my-16 md:mb-24 md:mt-20 flex flex-col max-w-common w-full mx-auto p-3">
        {/* top part */}
        <div className="flex justify-between text-xl font-semibold">
          <h1 className="md:text-3xl font-bold text-start md:text-center capitalize text-2xl">
            {firstPart}
            <span className="text-primary_color"> {secondPart}</span>
          </h1>
          <div>
            <Link to={"/news"}>
              <h4 className="border-2 border-primary_color text-nowrap p-2 md:px-5 rounded text-xs text-primary_color hover:bg-primary_color ease-in-out duration-300 hover:text-white cursor-pointer">
                {t("seeMore")}
              </h4>
            </Link>
          </div>
        </div>

        {/* blogs listing */}
        <div className="w-full overflow-x-auto">
          <div className="w-fit h-fit  mt-8 gap-x-8 flex">
            {Blog?.map((items) => (
              <div key={items?.id}>
                <ServiceRelatedBlogCard
                  data={items}
                  title={items?.title}
                  date={items?.postDate}
                  category={items?.category}
                  image={items?.icon}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

    </div>
  );
};

export default DepartmentDetailedView;
